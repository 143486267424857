<template>
  <div class="textmessage">
    <div class="textmessage_bottom">
      <div class="textmessage_bottom_text">
        <span>短信通知</span>
        <p style="margin-top:6px;">设置你要通知的类型以及通知的人员</p>
      </div>
      <div class="iconconte" v-for="(items, index) in arrdata" :key="index">
        <div class="iconconte_text">
          <p>
            通知类型
            <span>（可多选）</span>
          </p>
          <div @click="delebtn(index)" v-show="arrdata.length != 1" class="iconconte_text_rig">
            <i class="el-icon-delete"></i>
            <span>删除</span>
          </div>
        </div>

        <div class="checkbox_div">
          <el-checkbox class="chek-div" v-for="(itembtn, indexbtn) in items['headers']" :key="itembtn.type"
            @change="orderbtn($event, itembtn, indexbtn)" :label="itembtn.value" v-model="itembtn['selected']" border>
            <span>{{ itembtn.value }}</span>
            <img :class="[itembtn.selected ? 'active' : 'factive',]" src="../../../assets/icon/group_5_back_3.svg" alt />
            <!-- <img src="../../../assets/icon/group_5_back_3.svg" alt=""> -->
          </el-checkbox>
        </div>
        <div class="checkbox_div_form">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="选择通知人员" prop="name">
              <el-select v-model="items['notify_list']" value-key="id" multiple placeholder="请选择">
                <el-option v-for="item in webmasterList" :key="item.id" :label="item.name" :value="item">
                  <span class="name" style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px;margin-left:10px;">{{ item.phone }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btncka">
        <button @click="addbtn">
          <i class="el-icon-circle-plus-outline"></i>
          <span>添加成员</span>
        </button>
      </div>
    </div>

    <diy-footer-button v-if="hasPerm(['setting.sms_notify.update'])">
      <el-button type="primary" @click="cokeibtn">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
import { Message } from 'element-ui';

export default {
  data() {
    return {
      ifart: -99999,
      newarrdata: [],
      arrdata: [],
      options: [],
      value1: [],
      detalform: 1,
      radio: "1",
      ruleForm: {
        name: "",
        KeySecret: "",
        stencils: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入阿里云AccessKeyId",
            trigger: "blur"
          },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        KeySecret: [
          {
            required: true,
            message: "请输入阿里云AccessKeySecret",
            trigger: "blur"
          },
          { min: 3, max: 5, message: "长度在 3  到 5 个字符", trigger: "blur" }
        ],
        stencils: [
          { required: true, message: "请输入模板签名", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
      },
      webmasterList: []
    };
  },
  created() {
    this.getWebMasterList();
    this.initdx();
  },
  methods: {
    // 获取管理员列表
    getWebMasterList() {
      this.$get(this.$apis.permissonUserList).then(res => {
        if (res.code != 200) {
          return Message.error(res.message);
        }
        this.$set(this, 'webmasterList', res.data.map(item => ({ id: item.id, name: item.user_name, phone: item.mobile, email: item.email })));
      })
    },
    // 删除成员
    delebtn(eindex) {
      this.arrdata.splice(eindex, 1);
    },
    // 添加成员
    addbtn() {
      this.arrdata.push({
        headers: [
          {
            type: "order_create",
            selected: false,
            value: "订单下单提醒"
          },
          {
            type: "order_refund",
            selected: false,
            value: "订单退款提醒"
          },
          {
            type: "phone_verification_code",
            selected: false,
            value: "发送手机验证码"
          }
        ],
      });
    },
    // 选中与取消
    orderbtn(value, item) {
      this.$set(item, 'selected', value)
    },
    initdx() {
      this.$get(this.$apis.dtavsq)
        .then(res => {
          if (res.code == 200) {
            this.$set(this, "arrdata", res.data["notify.sms.list"]);
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch(err => {
          // console.log(err)
          this.common.message(this, err.message);
        });
    },
    fbuton() {
      this.$router.push("/setup/setup_home");
    },
    butonalli(numindex) {
      this.detalform = numindex;
    },
    // 保存
    cokeibtn() {
      // 验证通知类型至少选中一个
      const check = this.arrdata.every(item => {
        return item.headers.some(ite => ite.selected == true);
      })
      if (!check) {
        return Message.warning("请确保每个通知的信息通知类型至少选择一个!");
      }
      // 验证通知人员至少选择一个
      const checkMaster = this.arrdata.every(item => {
        return item.notify_list.length > 0
      })
      if (!checkMaster) {
        return Message.warning("请确保每个通知的通知人员至少选择一个!");
      }
      // 提交
      this.$put(this.$apis.dtavsq, { ["notify.sms.list"]: this.arrdata }).then(res => {
        if (res.code == 200) {
          this.$message.success("编辑短信通知成功!");
          this.initdx();
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    padding: 15px;

    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }

  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 32px 56px 168px 56px;
    box-sizing: border-box;

    .btncka {
      button {
        margin-top: 16px;
        width: 100px;
        height: 38px;
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #dddfe6;

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000d9a;
        }

        .el-icon-circle-plus-outline {
          font-size: 14px;
          color: #000d9a;
          margin-right: 2px;
        }
      }
    }

    .spanthre {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
    }

    p {
      width: 448px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    span {
      height: 20px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }

    .textmessage_bottom_text {
      margin-bottom: 24px;

      span:first-child {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000;
      }
    }

    .iconconte {
      .el-select {
        width: 356px;
        min-height: 38px;
      }

      .checkbox_div_form {
        min-height: 38px;

        /deep/.el-form-item__label {
          color: #333;
        }

        .el-form-item {
          margin-left: -14px;
        }
      }

      //   /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
      //   .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      //     display: none;
      //   }
      margin-top: 16px;
      padding: 20px 24px 24px 24px;
      box-sizing: border-box;
      background: #f9fafc;
      border-radius: 3px;

      //   display: flex;
      .checkbox_div {
        /deep/.el-checkbox.is-bordered.is-checked {
          border-color: #000d9a;
        }

        /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
          color: #333 !important;
        }

        padding: 14px;
        margin-bottom: 10px;

        /deep/.el-checkbox__label {
          display: inline-block;
          padding-left: 3px;
          line-height: 19px;
          font-size: 14px;
        }

        .chek-div {
          margin-left: -14px;
          width: 112px;
          height: 38px;
          position: relative;

          //       /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
          // margin-left: -7px;}
          /deep/.el-checkbox__inner {
            display: none;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            border-color: var(--fontColor, #fb6638) !important;
            background: var(--fontColor, #fb6638) !important;
            display: none;
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .active {
            position: absolute;
            top: 0;
            left: 90px;
          }

          .factive {
            position: absolute;
            top: 0;
            left: 90px;
            display: none;
          }
        }

        .chek-div:last-child {
          margin-left: -14px;
          width: 126px;
          height: 38px;

          /deep/.el-checkbox__inner {
            display: none;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            border-color: var(--fontColor, #fb6638) !important;
            background: var(--fontColor, #fb6638) !important;
            display: none;
          }

          .active {
            position: absolute;
            top: 0;
            left: 105px;
          }
        }

        .chek-div1 {
          margin-left: -14px;
          width: 126px;
          height: 38px;

          /deep/.el-checkbox__inner {
            display: none;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            border-color: var(--fontColor, #fb6638) !important;
            background: var(--fontColor, #fb6638) !important;
            display: none;
          }
        }
      }

      .iconconte_text {
        display: flex;
        justify-content: space-between;

        p {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }

      .iconconte_text_rig {
        cursor: pointer;

        .span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .el-icon-delete {
          font-size: 14px;
          color: #999999;
          margin-right: 2px;
        }
      }
    }
  }

  .bntout {
    margin: 60px 340px -100px;
  }

  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;

    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}

.name {
  min-width: 120px;
}
</style>